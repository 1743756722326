import React from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'
import mainHeader from '../../../images/solutions/bpm/mapeamento.jpg'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { FaqSection } from '../../../components/faq'
import { MethodologySection } from '../../../components/methodology'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { bpmList } from '../../../utils/customersList'
import BreadcrumbComponent from '../../../components/breadcrumb'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const faqItems = [
  {
    question: 'O que é preciso para iniciar o projeto de consultoria?',
    answer:
      'Primeiramente, o desejo de mudar. Todo projeto de Consultoria BPM impacta nos processos da empresa, portanto requer engajamento das pessoas para que as mudanças aconteçam. O apoio da alta direção também é fundamental para o sucesso do projeto. Em termos práticos, a equipe interna precisa ter disponibilidade para levantamento de informações e para implementar as ações de melhoria junto à consultoria.',
  },
  {
    question: 'Quanto tempo leva o projeto de consultoria?',
    answer:
      'Depende do nível de maturidade de gestão da empresa. Projetos de implementação da Gestão por Processos comumente demandam de 03 a 12 meses de execução. O cronograma depende de algumas variáveis, como o escopo do projeto, quantidade de processos envolvidos no mapeamento e melhoria, quantidade de funcionários e disponibilidade da equipe interna para trabalhar com a consultoria.',
  },
  {
    question: 'Como solicitar um orçamento para um projeto de consultoria?',
    answer:
      'Informe seus dados no formulário acima para receber o contato de um Especialista em Processos. Um especialista retornará o contato para coletar informações, através de uma reunião de diagnóstico. Mais do que um orçamento, é necessário entender qual o momento atual da empresa, quais são os principais desafios, para que então possamos oferecer a melhor solução.',
  },
  {
    question: 'Como saber se o projeto trará ROI para a minha empresa?',
    answer:
      'Projetos de consultoria são investimentos, e não despesas, portanto devem proporcionar ganhos para a organização. Em cada melhoria identificada, nossos Especialistas em Melhoria Contínua calculam os ganhos quantitativos e qualitativos esperados para cada mudança a ser implementada. Analisamos as principais variáveis dos processos para encontrar melhorias de valor, relacionadas a pessoas, riscos e oportunidades, lógica do processo, sistemas de informação e indicadores de desempenho.',
  },
]

const methodologyItems = [
  {
    count: 1,
    title: 'Planejamento',
    description: (
      <>
        A fase do planejamento é um processo comercial. Neste momento ainda não
        houve a contratação da consultoria. O objetivo desta etapa é alinhar
        todas as expectativas do projeto e os objetivos junto à contratante.{' '}
        <AnchorLink
          to={`/gestao-por-processos-de-negocio-bpm/mapeamento-e-melhoria#contact`}
        >
          Agende aqui essa etapa sem compromisso.
        </AnchorLink>
      </>
    ),
  },
  {
    count: 2,
    title: 'Mapeamento',
    description: (
      <>
        A etapa do mapeamento do processo é o momento em que identificamos as
        dores dos colaboradores da empresa cliente, quanto às atividades do dia
        a dia que levaram o processo a chegar à estrutura atual (AS IS).
      </>
    ),
  },
  {
    count: 3,
    title: 'Análise e melhoria',
    description: (
      <>
        Nesta etapa iremos analisar os processos diante de suas variáveis:
        pessoas, riscos e controles internos, lógica do fluxo do processo,
        sistemas de informação e indicadores de desempenho para sugerir
        melhorias. Será criada a nova versão de processo (TO BE).
      </>
    ),
  },
  {
    count: 4,
    title: 'Implementação',
    description: (
      <>
        Muito importante para a melhoria não ficar apenas na teoria. Utilizamos
        técnicas de GMO do instituto HCMI para engajar e "puxar" as mudanças
        para a empresa realmente implantar o que foi aprovado. Com reuniões
        periódicas unem-se os agentes das mudanças para reportarem os avanços do
        projeto.
      </>
    ),
  },
]

const featuresItems = [
  {
    title: `Pessoas envolvidas com o processo`,
    description: (
      <>
        São analisadas as pessoas envolvidas com o processo, necessidade de
        contratação, realocação e treinamentos. É preciso entender o ser humano,
        seus limites e potencialidades, bem como cuidar do engajamento e da
        comunicação.
      </>
    ),
  },
  {
    title: `Riscos e controles internos`,
    description: (
      <>
        São analisados os principais riscos inerentes aos processos e
        identificados pontos de controle para que o processo esteja em
        conformidade e seus riscos e impactos sejam mapeados e tratados.
      </>
    ),
  },
  {
    title: `Sistemas de informação`,
    description: (
      <>
        Além de entender a lógica do fluxo de informação, eliminar retrabalhos e
        excesso de aprovações, são identificadas oportunidades de sistematização
        e automatização de fluxos de trabalho. É proposta também uma análise de
        aderência do sistema utilizado ou qual se deseja implementar.
      </>
    ),
  },
  {
    title: `Indicadores de processos`,
    description: (
      <>
        São analisados indicadores de desempenho de processos (KPIs) para medir
        os resultados de determinadas ações ou estratégias utilizadas. Se os
        resultados esperados não estiverem sendo atingidos, os KPIs permitirão
        enxergar os gaps e tomar decisões assertivas para eliminá-los.
      </>
    ),
  },
]

const MapeamentoMelhoriaPage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location
  return (
    <Layout location={location}>
      <SEO title="High Jump - Mapeamento e melhoria de processos" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>Mapeamento, padronização e otimização de processos</h1>
              <p>
                Modelagem de processos para padronização, otimização e
                preparação para certificações, utilizando-se da notação BPMN.
                Modelagem de ponta a ponta, desde a criação de novos processos à
                sua expansão.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent
              replacedMainPath="Gestão por processos de negocio BPM"
              replacedLabel="Mapeamento e melhoria de processos"
            />
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Desenhar processos para obtenção de certificações,
                  estruturação de novos processos, atribuição de
                  responsabilidades e alavancar o desempenho. Confira as
                  principais variáveis:
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para implementar a mudança na sua organização."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={bpmList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Especialistas certificados</h2>
                  <p>
                    Em gestão por processos (ABPMP, OCEB) e gestão de projetos
                    (PMI)
                  </p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Quantificando e qualificando ganhos de projeto</p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default MapeamentoMelhoriaPage
